.not-found-container {
  position: relative;
  top: 10rem;
  left: 40rem;
  background-color: #e6e7e8;
  width: 30%;
  padding: 2rem;
}

.not-found-status {
  font-size: 1.75rem;
}

.not-found-message {
  font-weight: bold;
}
