
form{
  margin-top:5rem;

}

.login-form-container {  
  background-color: var(--first-color);
  color:var(--light-color);
  padding: 1.6rem;
  margin: 0 auto;
  position: relative;  
  display: flex;
  flex-flow: column;
  gap: 1.8rem;
  align-items: center;
  width: 25%;
  border-radius: 15px;
  /* box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.login-title {
  margin: 1rem auto 1rem auto;
  font-size:1.5rem;  
}

.login-field {
  display:flex;
  flex-direction: column;
  gap:0.6rem;
}


.login-input {
  padding: 0.7rem 0rem 0.7rem 1rem;
  width: 16vw;
  border-radius: 8px;
  outline:none;
  border:none;
  box-shadow: 0px 0px 1px 2px #D4D4D4;
}


.login-input:focus {  
  padding-left: 1rem;
  box-shadow: 0px 0px 1px 1px #D4D4D4;
}

.login-button {
  /* position: relative;
  left: 4rem; */
  width: 11rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 7px;
  border:none;
  outline: none;
  background-color: var(--second-color);
  color: var(--light-color);
}


.login-button:disabled {
  background-color: var(--second-color);
  color: var(--light-color);
}

.signup-text, .signup-text:link, .signup-text:hover {
  text-decoration: none;
  color: var(--light-color);
  font-weight: bold;
}

.login-error {
  position: relative;
  left: 48rem;
  background-color: #eec0c8;
  z-index: 1;
  text-align: center;
  padding: 1rem;
  display: inline;
}


/* Efecto en checkbox de remember credentials */

/* <div class="checkbox-wrapper-1">
  <input id="example-1" class="substituted" type="checkbox" aria-hidden="true" />
  <label for="example-1">Checkbox</label>
</div> */

  .checkbox-wrapper-1 *,
  .checkbox-wrapper-1 ::after,
  .checkbox-wrapper-1 ::before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted {
    margin: 0;
    width: 0;
    height: 0;
    display: inline;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted + label:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    height: 1.15em;
    width: 1.15em;
    margin-right: 0.6em;
    color: rgba(0, 0, 0, 0.275);
    border: solid 0.06em;
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
    border-radius: 0.2em;
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
    background-size: 0;
    will-change: color, border, background, background-size, box-shadow;
    transform: translate3d(0, 0, 0);
    transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:enabled + label:active:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
    background-color: #f0f0f0;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:checked + label:before {
    background-color: #3B99FC;
    background-size: 0.75em;
    color: rgba(0, 0, 0, 0.075);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled + label:active:before {
    background-color: #0a7ffb;
    color: rgba(0, 0, 0, 0.275);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:focus + label:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:focus:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:focus + label:active:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:disabled + label:before {
    opacity: 0.5;
  }

  .checkbox-wrapper-1 [type=checkbox].substituted.dark + label:before {
    color: rgba(255, 255, 255, 0.275);
    background-color: #222;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled + label:active:before {
    background-color: #444;
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked + label:before {
    background-color: #a97035;
    color: rgba(255, 255, 255, 0.075);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled + label:active:before {
    background-color: #c68035;
    color: rgba(0, 0, 0, 0.275);
  }

@media screen and (max-width:460px){
  .login-form-container{
    width:75%
  }

  .login-input {
    width: 50vw;
  }

}