@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
:root {
  --first-color: #13C1AC;
  --second-color: #c11376;
  /* --second-color:  #1EE9D0; */
  --light-color:#FFFFFF;
  --dark-color:#000000;
  --shade-color-1: #D0F3EE;
  --shade-color-2: #5AD4C5; 
  --logo-font: "Happy Monkey";
}

html, body {
  margin: 0;
  padding: 0;
  border:0;
  width:100vw;
  font-family: "Open Sans", "sans-serif";
  background-color: #FFFFFF;
  background-color: var(--light-color);
  overflow-x: hidden;
}



.navbar {
  background-color: var(--light-color);
  color: var(--first-color);
  display:flex;
  align-items:center;
  padding-top:1rem;
  padding-bottom: 1rem;
  padding-right:2rem;
}


.logo, .logo:hover {
  margin-left: 3rem;
  margin-top:1rem;
  display: block;
  float: left;
  color:var(--first-color);
  font-weight: 400;
  font-size: 2rem;
  font-family: var(--logo-font), 'Courier New', Courier, monospace;
  text-decoration: none;
}

.logo-icon {
  font-size: 1em;
  display: inline-block;
  width: 0.4em;
  box-sizing: content-box;
  height: 0.4em;
  border: 0.1em solid #c11376;
  position: relative;
  bottom:0.3em;
  border-radius: 0.35em;
}

.logo-icon:before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -0.25em;
  bottom: -0.1em;
  border-width: 0;
  background: #c11376;
  width: 0.35em;
  height: 0.08em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}


.header-button {
  font-size: 18px;
  background-color: var(--first-color);
  color: var(--light-color);
  padding: 8px 20px;
  margin-right: 2rem;
  border:none;
  border-radius: 5px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
}

.header-button:hover {
color: var(--light-color);
background-color: var(--second-color);
border-color: var(--first-color);
}

@media screen and (max-width:460px){
  .navbar{
 flex-direction:column;
 align-items: center;
  }

  .header-button{
    margin-left:3.5rem;
    margin-top:2rem;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  
}

.modal-section {
  padding: 1.5rem 1rem;
  position: fixed;
  background: #e6e7e8;
  width: auto;
  height: auto;
  top: 15%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.show-modal {
  display: block;
}

.modal-button {
  margin-left: 0.75rem;
}


form{
  margin-top:5rem;

}

.login-form-container {  
  background-color: var(--first-color);
  color:var(--light-color);
  padding: 1.6rem;
  margin: 0 auto;
  position: relative;  
  display: flex;
  flex-flow: column;
  grid-gap: 1.8rem;
  gap: 1.8rem;
  align-items: center;
  width: 25%;
  border-radius: 15px;
  /* box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.login-title {
  margin: 1rem auto 1rem auto;
  font-size:1.5rem;  
}

.login-field {
  display:flex;
  flex-direction: column;
  grid-gap:0.6rem;
  gap:0.6rem;
}


.login-input {
  padding: 0.7rem 0rem 0.7rem 1rem;
  width: 16vw;
  border-radius: 8px;
  outline:none;
  border:none;
  box-shadow: 0px 0px 1px 2px #D4D4D4;
}


.login-input:focus {  
  padding-left: 1rem;
  box-shadow: 0px 0px 1px 1px #D4D4D4;
}

.login-button {
  /* position: relative;
  left: 4rem; */
  width: 11rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 7px;
  border:none;
  outline: none;
  background-color: var(--second-color);
  color: var(--light-color);
}


.login-button:disabled {
  background-color: var(--second-color);
  color: var(--light-color);
}

.signup-text, .signup-text:link, .signup-text:hover {
  text-decoration: none;
  color: var(--light-color);
  font-weight: bold;
}

.login-error {
  position: relative;
  left: 48rem;
  background-color: #eec0c8;
  z-index: 1;
  text-align: center;
  padding: 1rem;
  display: inline;
}


/* Efecto en checkbox de remember credentials */

/* <div class="checkbox-wrapper-1">
  <input id="example-1" class="substituted" type="checkbox" aria-hidden="true" />
  <label for="example-1">Checkbox</label>
</div> */

  .checkbox-wrapper-1 *,
  .checkbox-wrapper-1 ::after,
  .checkbox-wrapper-1 ::before {
    box-sizing: border-box;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted {
    margin: 0;
    width: 0;
    height: 0;
    display: inline;
    -webkit-appearance: none;
    appearance: none;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted + label:before {
    content: "";
    display: inline-block;
    vertical-align: top;
    height: 1.15em;
    width: 1.15em;
    margin-right: 0.6em;
    color: rgba(0, 0, 0, 0.275);
    border: solid 0.06em;
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset, 0 0 0 0.07em transparent inset;
    border-radius: 0.2em;
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>') no-repeat center, white;
    background-size: 0;
    will-change: color, border, background, background-size, box-shadow;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:enabled + label:active:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset;
    background-color: #f0f0f0;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:checked + label:before {
    background-color: #3B99FC;
    background-size: 0.75em;
    color: rgba(0, 0, 0, 0.075);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:checked:enabled + label:active:before {
    background-color: #0a7ffb;
    color: rgba(0, 0, 0, 0.275);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:focus + label:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:focus:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted:focus + label:active:before {
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(0, 0, 0, 0.1) inset, 0 0 0 3.3px rgba(65, 159, 255, 0.55), 0 0 0 5px rgba(65, 159, 255, 0.3);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted:disabled + label:before {
    opacity: 0.5;
  }

  .checkbox-wrapper-1 [type=checkbox].substituted.dark + label:before {
    color: rgba(255, 255, 255, 0.275);
    background-color: #222;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:enabled + label:active:before {
    background-color: #444;
    box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em transparent inset, 0 0 0 0.07em rgba(255, 255, 255, 0.1) inset;
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked + label:before {
    background-color: #a97035;
    color: rgba(255, 255, 255, 0.075);
  }
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled:active + label:before,
  .checkbox-wrapper-1 [type=checkbox].substituted.dark:checked:enabled + label:active:before {
    background-color: #c68035;
    color: rgba(0, 0, 0, 0.275);
  }

@media screen and (max-width:460px){
  .login-form-container{
    width:75%
  }

  .login-input {
    width: 50vw;
  }

}


.footer {
  display: flex;
  justify-content: center;
  grid-gap: 3rem;
  gap: 3rem;
  padding: 1rem 1rem;
  margin-top: 16rem;
  background-color: var(--first-color);
  color: var(--light-color);
  height: auto;
  
 
}

.footer a {
  color: #000000;
}


.footer  svg:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


.custom-card-list {
margin: 0 2.5rem 0 2.5rem;
display:flex;
flex-flow: row wrap;
grid-gap:1rem;
gap:1rem;
justify-content: center;
align-items:center;
}

.custom-card{
 width: 18vw; 
 background-color:var(--light-color); 
 border:none;
 /* box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 3px; */
 box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
 list-style-type: none;
}

.custom-card:hover  {
  /* box-shadow: rgba(0, 0, 0, 0.15) 3px 6px 6px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 1px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.custom-card-link, .custom-card-link:hover, .custom-card-link:link, .custom-card-link:visited  {
  text-decoration: none;
  color: var(--dark-color);
  
}

.custom-card-image {
  height:auto; 
  max-width: 100%;
}


.custom-card-body {
  padding: 2rem 1rem;
}


.custom-card-price {
  font-size:26px;
  font-weight: bold;
}

.custom-card-title {
  font-size: 20px;
}
.custom-card-sale {
  font-size:18px;
  font-weight: bold;
  font-family: "Happy Monkey";
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  float:right;
}

.badge-wrapper {
  display:flex;
  grid-gap:0.5rem;
  gap:0.5rem;
}
.custom-card-badge  {
  background-color: var(--first-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 11px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}
.adverts-page-error {
  position: relative;
  top: 1.5em;
  left: 40rem;
  background-color: #eec0c8;
  width: 30%;
  text-align: center;
  padding: 0.5rem;
}

@media screen and (max-width:460px){
  .custom-card-list{
    grid-gap:2rem;
    gap:2rem;
  }

  .custom-card{
    width: 60vw; 
  }
  

}
.custom-form {
  width: 100vw;
}
.custom-search-bar-group {
  width: 73vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.custom-search-bar {
  width: 73vw;
  margin: 0 auto;
  padding: 0.5rem 0.8rem;
  border-radius: 16px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 1px 2px #d4d4d4;
}

.filter-area {
  padding-top: 6rem;
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  color: var(--light-color);
  font-weight: bold;
}

.filter-price {
  background-color: var(--shade-color-2);
  padding: 2rem 5rem 3.5rem 3.5rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 4rem;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.filter-sale {
  background-color: var(--shade-color-2);
  padding: 3rem 0rem 2.5rem 4rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 2rem;
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.filter-sale-true {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  align-items: center;
}

.filter-sale-false {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  align-items: center;
}
.filter-sale-all {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  align-items: center;
}

.filter-tags {
  background-color: var(--shade-color-2);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 13rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

#tags {
  /* display:flex;
  flex-flow: column nowrap; */
}
.filter-title {
  /* margin-left: 4rem;
  margin-bottom: 3.5rem; */
}

.filter-label {
  /* width: 6rem;
  flex: 0 1 auto; */
}

.tags {
  /* position: relative;
  right: -10rem; */
}


@media screen and (max-width:460px){
  
  .filter-area{
    flex-flow:column nowrap;   
    }

    .filter-price, .filter-tags, .filter-sale {
      width:90vw;
      margin-left:2rem;
     }

}
.empty-container {
  /* position: relative;
  top: 1rem;
  left: 12rem; */
  margin: 0 auto;
  width: 60%;
  height: 100%;
  border: solid 1px black;
  padding: 5rem 10rem 5rem 10rem;
  background-color: var(--shade-color-2);
  color:var(--light-color);
  border-radius: 9px;
  border:none;
  font-size: 0.8rem;
}


.empty-main-message {
  font-size: 1.5rem;
  text-align: center;
}

.empty-call-to-action {
  padding-top: 1.5rem;
  position: relative;
  left: 24rem;
  font-size: 1.25rem;
}



.custom-detail-card-container {
  position: relative;
  top: 7rem;
  margin: 0 auto;
  width: 65vw;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
  /* box-shadow: var(--first-color) 1px 3px 4px; */
  padding:7rem;
  border:none;
  background-color: var(--light-color);
  border-radius: 10px;
}

.custom-detail-card-image {
  /* max-width: 100%; */
  max-width: 500px;
  height: auto;
}

.custom-detail-card-content-and-button {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
}

.custom-detail-price {
font-size:2rem;
font-weight: bold;
}

.custom-detail-card-title {
  font-size: 1.5rem;
  display: inline-block;
}

.custom-detail-card-sale {
  font-size:1.2rem;
}


.custom-card-delete-button {
  width: 6rem;
  height: 2.6rem;
  border-radius: 7px;
  border:none;
  outline: none;
  background-color: var(--second-color);
  color: var(--light-color);

}

.badge-wrapper {
  display:flex;
  grid-gap:0.5rem;
  gap:0.5rem;
}
.custom-card-badge  {
  background-color: var(--first-color);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 11px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}

.new-advert-form-wrapper{
  margin:0 auto;
  background-color: var(--first-color);
  color: var(--light-color);
  width:36vw;
  height: 110vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;  */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 15px;
  font-size: 0.8rem !important;
  
}

.form{
  display:flex;
  flex-direction: column;
  justify-content: center;
  grid-gap:3rem;
  gap:3rem;
  height: inherit;
  
}

.new-advert-title {
  font-size:1.2rem;
  position:relative;
  top:5rem;    

}

.field-group {
  display:flex;
  flex-direction: column;
  grid-gap:0.6rem;
  gap:0.6rem;
  margin: 0 auto;
}

.new-advert-input {
  padding: 0.4rem 0rem 0.7rem 1rem;
  width: 18vw;
  border-radius: 8px;
  outline:none;
  border:none;
  box-shadow: 0px 0px 1px 2px #D4D4D4;
}

.new-advert-input:focus {
  padding-left: 1rem;
  box-shadow: 0px 0px 1px 1px #D4D4D4;
}


.sale-field-group {
  display:flex;
  justify-content: center;
  grid-gap:5rem;
  gap:5rem;
}

.sell-field-group {
  display:flex;
  flex-direction: row;
}

.buy-field-group {
  display:flex;
}

.file-input {
  font-size: 0.9rem;
}

.new-advert-button {
  background-color: var(--second-color);
  color: var(--light-color);
  height: 45px;
  border-radius: 7px;
  margin-top:1rem;
  margin-bottom:1rem;
}

.new-advert-button:disabled {
  background-color: var(--second-color);
  color: var(--light-color);
}
.signup-form-wrapper {
    background-color: var(--first-color);
    color: var(--light-color);
    height: 110vh;
    width:30vw;
    border-radius: 9px;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    /* margin-top:1rem; */
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-size:1rem !important;
}

.form-title {
font-size:1.2rem;
padding-bottom: 2.3rem;
padding-top:5rem;
}

.signup-form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap:2rem;
    gap:2rem; 
    margin: 0 auto; 
}

.signup-group {
    display:flex;
    flex-direction: column;
    grid-gap:0.7rem;
    gap:0.7rem;
}

.signup-input {
    width:18vw;
    padding: 0.5rem 0rem 0.7rem 1rem;
    border-radius: 8px;
    outline:none;
    border:none;
    box-shadow: 0px 0px 1px 2px #D4D4D4;
    display:flex;

}

.signup-input:focus {  
    padding-left: 1rem;
    box-shadow: 0px 0px 1px 1px #D4D4D4;
  }
  

.signup-button {
    width: 11rem;
    height: 3rem;
    margin: 2rem 0 auto;
    border-radius: 7px;
    border:none;
    outline: none;
    background-color: var(--second-color);
    color: var(--light-color);
    /* position:relative;
    top:1rem; */
}

@media screen and (max-width:460px){
    .signup-form-wrapper{
    width:80vw;
}
.signup-input{
    width: 50vw;
}
.signup-button{
    margin-top: 0.5rem;
}
}
.not-found-container {
  position: relative;
  top: 10rem;
  left: 40rem;
  background-color: #e6e7e8;
  width: 30%;
  padding: 2rem;
}

.not-found-status {
  font-size: 1.75rem;
}

.not-found-message {
  font-weight: bold;
}

