.signup-form-wrapper {
    background-color: var(--first-color);
    color: var(--light-color);
    height: 110vh;
    width:30vw;
    border-radius: 9px;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    /* margin-top:1rem; */
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    font-size:1rem !important;
}

.form-title {
font-size:1.2rem;
padding-bottom: 2.3rem;
padding-top:5rem;
}

.signup-form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem; 
    margin: 0 auto; 
}

.signup-group {
    display:flex;
    flex-direction: column;
    gap:0.7rem;
}

.signup-input {
    width:18vw;
    padding: 0.5rem 0rem 0.7rem 1rem;
    border-radius: 8px;
    outline:none;
    border:none;
    box-shadow: 0px 0px 1px 2px #D4D4D4;
    display:flex;

}

.signup-input:focus {  
    padding-left: 1rem;
    box-shadow: 0px 0px 1px 1px #D4D4D4;
  }
  

.signup-button {
    width: 11rem;
    height: 3rem;
    margin: 2rem 0 auto;
    border-radius: 7px;
    border:none;
    outline: none;
    background-color: var(--second-color);
    color: var(--light-color);
    /* position:relative;
    top:1rem; */
}

@media screen and (max-width:460px){
    .signup-form-wrapper{
    width:80vw;
}
.signup-input{
    width: 50vw;
}
.signup-button{
    margin-top: 0.5rem;
}
}