
.new-advert-form-wrapper{
  margin:0 auto;
  background-color: var(--first-color);
  color: var(--light-color);
  width:36vw;
  height: 110vh;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;  */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  border-radius: 15px;
  font-size: 0.8rem !important;
  
}

.form{
  display:flex;
  flex-direction: column;
  justify-content: center;
  gap:3rem;
  height: inherit;
  
}

.new-advert-title {
  font-size:1.2rem;
  position:relative;
  top:5rem;    

}

.field-group {
  display:flex;
  flex-direction: column;
  gap:0.6rem;
  margin: 0 auto;
}

.new-advert-input {
  padding: 0.4rem 0rem 0.7rem 1rem;
  width: 18vw;
  border-radius: 8px;
  outline:none;
  border:none;
  box-shadow: 0px 0px 1px 2px #D4D4D4;
}

.new-advert-input:focus {
  padding-left: 1rem;
  box-shadow: 0px 0px 1px 1px #D4D4D4;
}


.sale-field-group {
  display:flex;
  justify-content: center;
  gap:5rem;
}

.sell-field-group {
  display:flex;
  flex-direction: row;
}

.buy-field-group {
  display:flex;
}

.file-input {
  font-size: 0.9rem;
}

.new-advert-button {
  background-color: var(--second-color);
  color: var(--light-color);
  height: 45px;
  border-radius: 7px;
  margin-top:1rem;
  margin-bottom:1rem;
}

.new-advert-button:disabled {
  background-color: var(--second-color);
  color: var(--light-color);
}