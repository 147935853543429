

.custom-detail-card-container {
  position: relative;
  top: 7rem;
  margin: 0 auto;
  width: 65vw;
  display: flex;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
  /* box-shadow: var(--first-color) 1px 3px 4px; */
  padding:7rem;
  border:none;
  background-color: var(--light-color);
  border-radius: 10px;
}

.custom-detail-card-image {
  /* max-width: 100%; */
  max-width: 500px;
  height: auto;
}

.custom-detail-card-content-and-button {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.custom-detail-price {
font-size:2rem;
font-weight: bold;
}

.custom-detail-card-title {
  font-size: 1.5rem;
  display: inline-block;
}

.custom-detail-card-sale {
  font-size:1.2rem;
}


.custom-card-delete-button {
  width: 6rem;
  height: 2.6rem;
  border-radius: 7px;
  border:none;
  outline: none;
  background-color: var(--second-color);
  color: var(--light-color);

}

.badge-wrapper {
  display:flex;
  gap:0.5rem;
}
.custom-card-badge  {
  background-color: var(--first-color);
  width: fit-content;
  border-radius: 11px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}