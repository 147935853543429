@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Happy+Monkey&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

:root {
  --first-color: #13C1AC;
  --second-color: #c11376;
  /* --second-color:  #1EE9D0; */
  --light-color:#FFFFFF;
  --dark-color:#000000;
  --shade-color-1: #D0F3EE;
  --shade-color-2: #5AD4C5; 
  --logo-font: "Happy Monkey";
}

html, body {
  margin: 0;
  padding: 0;
  border:0;
  width:100vw;
  font-family: "Open Sans", "sans-serif";
  background-color: var(--light-color);
  overflow-x: hidden;
}


