

.footer {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 1rem 1rem;
  margin-top: 16rem;
  background-color: var(--first-color);
  color: var(--light-color);
  height: auto;
  
 
}

.footer a {
  color: #000000;
}


.footer  svg:hover {
  transform: scale(1.1);
}
