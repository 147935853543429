.custom-form {
  width: 100vw;
}
.custom-search-bar-group {
  width: 73vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.custom-search-bar {
  width: 73vw;
  margin: 0 auto;
  padding: 0.5rem 0.8rem;
  border-radius: 16px;
  outline: none;
  border: none;
  box-shadow: 0px 0px 1px 2px #d4d4d4;
}

.filter-area {
  padding-top: 6rem;
  padding-bottom: 7rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: var(--light-color);
  font-weight: bold;
}

.filter-price {
  background-color: var(--shade-color-2);
  padding: 2rem 5rem 3.5rem 3.5rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 4rem;
  display: flex;
  gap: 30px;
}

.filter-sale {
  background-color: var(--shade-color-2);
  padding: 3rem 0rem 2.5rem 4rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 2rem;
  display: flex;
  gap: 1.5rem;
}

.filter-sale-true {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.filter-sale-false {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}
.filter-sale-all {
  display: flex;
  gap: 0.4rem;
  align-items: center;
}

.filter-tags {
  background-color: var(--shade-color-2);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
  width: 26rem;
  height: 13rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#tags {
  /* display:flex;
  flex-flow: column nowrap; */
}
.filter-title {
  /* margin-left: 4rem;
  margin-bottom: 3.5rem; */
}

.filter-label {
  /* width: 6rem;
  flex: 0 1 auto; */
}

.tags {
  /* position: relative;
  right: -10rem; */
}


@media screen and (max-width:460px){
  
  .filter-area{
    flex-flow:column nowrap;   
    }

    .filter-price, .filter-tags, .filter-sale {
      width:90vw;
      margin-left:2rem;
     }

}