.navbar {
  background-color: var(--light-color);
  color: var(--first-color);
  display:flex;
  align-items:center;
  padding-top:1rem;
  padding-bottom: 1rem;
  padding-right:2rem;
}


.logo, .logo:hover {
  margin-left: 3rem;
  margin-top:1rem;
  display: block;
  float: left;
  color:var(--first-color);
  font-weight: 400;
  font-size: 2rem;
  font-family: var(--logo-font), 'Courier New', Courier, monospace;
  text-decoration: none;
}

.logo-icon {
  font-size: 1em;
  display: inline-block;
  width: 0.4em;
  box-sizing: content-box;
  height: 0.4em;
  border: 0.1em solid #c11376;
  position: relative;
  bottom:0.3em;
  border-radius: 0.35em;
}

.logo-icon:before {
  content: "";
  display: inline-block;
  position: absolute;
  right: -0.25em;
  bottom: -0.1em;
  border-width: 0;
  background: #c11376;
  width: 0.35em;
  height: 0.08em;
  transform: rotate(45deg);
}


.header-button {
  font-size: 18px;
  background-color: var(--first-color);
  color: var(--light-color);
  padding: 8px 20px;
  margin-right: 2rem;
  border:none;
  border-radius: 5px;
  box-shadow: rgba(80, 63, 205, 0.5) 0 3px 6px;
}

.header-button:hover {
color: var(--light-color);
background-color: var(--second-color);
border-color: var(--first-color);
}

@media screen and (max-width:460px){
  .navbar{
 flex-direction:column;
 align-items: center;
  }

  .header-button{
    margin-left:3.5rem;
    margin-top:2rem;
  }
}