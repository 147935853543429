.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  
}

.modal-section {
  padding: 1.5rem 1rem;
  position: fixed;
  background: #e6e7e8;
  width: auto;
  height: auto;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-modal {
  display: block;
}

.modal-button {
  margin-left: 0.75rem;
}
