
.custom-card-list {
margin: 0 2.5rem 0 2.5rem;
display:flex;
flex-flow: row wrap;
gap:1rem;
justify-content: center;
align-items:center;
}

.custom-card{
 width: 18vw; 
 background-color:var(--light-color); 
 border:none;
 /* box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 3px; */
 box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
 list-style-type: none;
}

.custom-card:hover  {
  /* box-shadow: rgba(0, 0, 0, 0.15) 3px 6px 6px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 1px 1px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.custom-card-link, .custom-card-link:hover, .custom-card-link:link, .custom-card-link:visited  {
  text-decoration: none;
  color: var(--dark-color);
  
}

.custom-card-image {
  height:auto; 
  max-width: 100%;
}


.custom-card-body {
  padding: 2rem 1rem;
}


.custom-card-price {
  font-size:26px;
  font-weight: bold;
}

.custom-card-title {
  font-size: 20px;
}
.custom-card-sale {
  font-size:18px;
  font-weight: bold;
  font-family: "Happy Monkey";
  transform: rotate(-45deg);
  float:right;
}

.badge-wrapper {
  display:flex;
  gap:0.5rem;
}
.custom-card-badge  {
  background-color: var(--first-color);
  width: fit-content;
  border-radius: 11px;
  padding: 4px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}
.adverts-page-error {
  position: relative;
  top: 1.5em;
  left: 40rem;
  background-color: #eec0c8;
  width: 30%;
  text-align: center;
  padding: 0.5rem;
}

@media screen and (max-width:460px){
  .custom-card-list{
    gap:2rem;
  }

  .custom-card{
    width: 60vw; 
  }
  

}